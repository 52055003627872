import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DisclaimerStateService {
  private hasShownDialog = false;

  // To persist state across sessions, use localStorage:
  isDialogShown(): boolean {
    return this.hasShownDialog || localStorage.getItem('disclaimerShown') === 'true';
  }

  setDialogShown(): void {
    this.hasShownDialog = true;
    localStorage.setItem('disclaimerShown', 'true'); // Save state for future visits
  }
}
